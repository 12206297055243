@import '../../styles/theme';

.ant-layout {
  .affix-nav {
    z-index: 5000;
    .ant-layout-header {
      padding: 0;
      .header-content {
        padding: 0 10px 0 20px;
        height: 64px;
        width: 100%;
        background-color: @mainColor;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 1px 4px 0 rgba(0, 21, 41, 0.12);
        position: relative;
        color: @white;
        .locale-select {
          .ant-select-selection-item {
            color: white;
          };
          .ant-select-arrow {
            color: white;
          }
        }
        .full-flex {
          .user-setting-menu {
            cursor: pointer;
            display: flex;
            margin-left: 8px;
            .anticon-caret-down {
              margin: 0 8px;
            }
            .ant-typography {
              color: white;
              margin-bottom: 0;
            }
          }
          .anticon-setting-config {
            cursor: pointer;
            position: relative;
            top: 5px;
            svg {
              width: 20px;
              height: 20px;
              color: white;
            }
          }
        }
      }
    }
  }
}

.ant-menu-submenu-title {
  a {
    color: rgba(0, 0, 0, 0.65);
  }
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}

.ant-layout-sider {
  background-color: @white;
}

.menu-wrapper {
  .ant-affix {
    height: 100% !important;
    box-shadow: 2px 0 6px 0 rgba(0, 21, 41, 0.35);
    padding-bottom: 65px;
  }

  .aside-menu {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
  /* width */
  ::-webkit-scrollbar {
    width: 3px;
  }
  background-color: @white;
  .aside-menu {
    min-height: 100%;
    left: 0;
    box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
    z-index: 15;
    background-color: @white;
    i.anticon {
      transform: translate(0px, 2px);
    }
    .ant-menu-item-selected {
      background-color: @mainColor1 !important;
      font-weight: bold;
    }
    .ant-menu-submenu-selected {
      >div.ant-menu-submenu-title {
        font-weight: bold;
      }
    }
    ul.ant-menu-root {
      >li {
        >div.ant-menu-submenu-title {
          font-weight: bold;
        }
      }
    }
  }
}

.side-menu-logo {
  .logo-text {
    font-size: 22px;
    font-weight: 500;
    color: @white;
  }
}

.toggle-collapse-btn {
  margin: auto 24px;
}

.ant-dropdown-placement-bottomRight {
  min-width: 160px !important;
  .ant-dropdown-menu-item {
    padding: 5px 20px;
  }
}

.ant-select-dropdown-placement-bottomLeft {
  z-index: 6000 !important;
}

.product-select {
  margin: 10px 10px 0;
  .ant-select {
    width: 100%;
    .ant-select-selection-item {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
@primary-color: #0947A6;@link-color: #007aff;@error-color: #ae0d00;@font-size-base: 14px;@text-color: #333749;@text-color-secondary: #70737f;@border-color-base: #c7c7cc;@input-placeholder-color: #adaeb6;