@import '~antd/dist/antd.less';
@import "styles/theme.less";

html { height:100%; }

@font-face {
  font-family: Nunito;
  src: url('font/Nunito/Nunito-Regular.ttf');
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f2f5
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-container {
  min-height: 100vh;
  width: 100%;
}

.content-wrapper {
  padding: 20px;
  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .guest-layout {
    .ant-card-type-inner {
      .ant-card-head-title {
        font-size: 20px;
      }
    }
  }
}
.full-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-btn {
  border-radius: 3px;
  height: 40px;
}

.ant-input-affix-wrapper-focused, .ant-select-focused {
  box-shadow: none;
}

.ant-divider-horizontal {
  margin: 16px 0;
}

.ant-typography {
  margin-bottom: 0;
}

.text-bold {
  font-weight: bold;
}

.ant-pagination-options {
  display: none;
}

.ant-modal-title {
  font-weight: bold;
}

.ant-modal-confirm-title, .ant-select-lg, .ant-input-lg, .ant-picker-input>input, .ant-alert-message {
  font-size: @fontSize !important;
}

.ant-tabs-tab-btn {
  font-weight: bold !important;
  font-size: 16px;
}

.input-modal {
  .ant-modal-content {
    padding-right: 10px;
    .ant-modal-body {
      padding-bottom: 0;
      max-height: 70vh;
      overflow-y: auto;
    }
    .ant-modal-footer {
      padding: 10px 24px;
    }
  }
}

.explain {
  color: @explain;
  text-align: justify;
  font-size: @errorExplainTextSize;
}

.ant-form-item-label {
  text-align: unset;
}

.tab-control {
  overflow: unset;
  .ant-tabs-tab-disabled {
    display: none;
  }
}
@primary-color: #0947A6;@link-color: #007aff;@error-color: #ae0d00;@font-size-base: 14px;@text-color: #333749;@text-color-secondary: #70737f;@border-color-base: #c7c7cc;@input-placeholder-color: #adaeb6;